import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/expertises',
        name: 'Expertises',
        component: () => import('../views/Expertises.vue'),
    },
    {
        path: '/apply',
        name: 'Apply',
        component: () => import('../views/Apply.vue'),
    },
    {
        path: '/about',
        name: 'About',

        component: () => import('../views/About.vue'),
    },
    {
        path: '/realisations',
        name: 'Realisations',

        component: () => import('../views/Realisations.vue'),
    },
    {
        path: '/contact/:id',
        name: 'Contact',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Contact.vue'),
        props: true,
    },
    {
        path: '/contact',
        name: 'Contact',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Contact.vue'),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;

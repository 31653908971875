<template>
    <v-container
        class="wide white--text"
        fluid
        style="padding: 0px">
        <div id="particles-js"></div>
        <article
            style="
                position: absolute;
                z-index: 8;
                top: calc(70vh + 25px);
                width: 100vw;
                display: flex;
                flex-direction: column;
                align-content: center;
                align-items: center;
            ">
            <h1 style="opacity: 0; user-select: none">Let's build the furture together</h1>
            <p
                class="text-center px-2"
                style="max-width: 500px">
                Parce que chaque entreprise est unique, il est essentiel de lui développer une solution numérique
                adaptée à son fonctionnement.
            </p>
        </article>
        <div class="white--text banner">
            <div class="banner-tw">
                <div>
                    <v-row
                        style="display: flex; align-items: baseline; justify-content: center"
                        no-gutters
                        class="no-wrap">
                        <v-col
                            cols="3"
                            md="3"
                            :class="
                                $vuetify.breakpoint.smAndDown
                                    ? 'text-body-1 light'
                                    : $vuetify.breakpoint.lgAndUp
                                    ? 'text-h2 light text-end'
                                    : 'text-h3 light'
                            "
                            id="lets"
                            >let's</v-col
                        >
                        <v-col
                            cols="7"
                            md="7"
                            id="build"
                            :class="
                                $vuetify.breakpoint.smAndDown
                                    ? 'text-h4 bold'
                                    : $vuetify.breakpoint.lgAndUp
                                    ? 'text-ularge bold'
                                    : 'text-h2 bold'
                            ">
                            build
                        </v-col>
                    </v-row>
                    <v-row
                        style="display: flex; align-items: baseline; justify-content: center"
                        no-gutters
                        class="no-wrap">
                        <v-col
                            cols="3"
                            md="3"
                            :class="
                                $vuetify.breakpoint.smAndDown
                                    ? 'text-body-1 light'
                                    : $vuetify.breakpoint.lgAndUp
                                    ? 'text-h2 pl-7 light text-end'
                                    : 'text-h3 light'
                            "
                            id="the"
                            >the</v-col
                        >
                        <v-col
                            cols="7"
                            md="7"
                            id="future"
                            :class="
                                $vuetify.breakpoint.smAndDown
                                    ? 'text-h4 bold'
                                    : $vuetify.breakpoint.lgAndUp
                                    ? 'text-ularge bold'
                                    : 'text-h2 bold'
                            ">
                            future
                        </v-col>
                    </v-row>
                    <v-row
                        style="display: flex; align-items: baseline; justify-content: center"
                        no-gutters
                        class="no-wrap">
                        <v-col
                            cols="3"
                            md="3"></v-col>
                        <v-col
                            cols="7"
                            md="7"
                            id="together"
                            :class="
                                $vuetify.breakpoint.smAndDown
                                    ? 'text-h4 bold'
                                    : $vuetify.breakpoint.lgAndUp
                                    ? 'text-ularge pl-5 bold'
                                    : 'text-h2 bold ml-n15'
                            ">
                            together
                        </v-col>
                    </v-row>
                </div>

                <!--   <div id="imagine" class="mt-10" :class="$vuetify.breakpoint.smAndDown ? 'text-h3 text-body-2' : 'text-h6'">Imagine and Realize... with Meridius !</div> -->
                <v-spacer></v-spacer>
            </div>
            <div class="banner-lw">
                <v-img
                    id="logo"
                    src="/logow.png"
                    height="75"
                    contain
                    class="logo-fixe"
                    alt="Meridius AI Solutions"
                    v-if="!$vuetify.breakpoint.smAndDown"></v-img>
                <svg
                    id="logo"
                    v-if="$vuetify.breakpoint.smAndDown"
                    xmlns="http://www.w3.org/2000/svg"
                    width="45"
                    height="45"
                    viewBox="0 0 77.654 77.654"
                    class="logo-fixe mb-2">
                    <g
                        id="Groupe_2"
                        data-name="Groupe 2"
                        transform="translate(-166.184 -2.322)">
                        <g
                            id="Groupe_1"
                            data-name="Groupe 1"
                            transform="translate(166.184 2.322)">
                            <path
                                id="path_1"
                                data-name="Tracé 1"
                                d="M205.011,79.976a38.827,38.827,0,1,1,38.827-38.827A38.871,38.871,0,0,1,205.011,79.976Zm0-72.623a33.8,33.8,0,1,0,33.8,33.8A33.834,33.834,0,0,0,205.011,7.353Z"
                                transform="translate(-166.184 -2.322)"
                                fill="#fff"></path>
                        </g>
                        <path
                            id="path_2"
                            data-name="Tracé 2"
                            d="M210.034,63.591a6.4,6.4,0,0,1,6.157-4.25c3.972,0,7.07,2.661,7.07,6.712V79.836c0,1.628.715,2.383,2.026,2.383.913,0,1.311-.556,1.668-1.669l5.481-16.96a6.348,6.348,0,0,1,6.157-4.25c3.972,0,7.07,2.661,7.07,6.712V86.787h-5.084V66.53c0-1.589-.715-2.383-2.066-2.383-.874,0-1.271.635-1.628,1.747l-5.561,16.921a6.159,6.159,0,0,1-6.116,4.29c-3.932,0-7.03-2.661-7.03-6.713V66.53c0-1.589-.715-2.383-2.065-2.383-.874,0-1.231.635-1.629,1.747l-7.308,20.893h-5.4Z"
                            transform="translate(-20.019 -32.074)"
                            fill="#fff"></path>
                    </g>
                </svg>

                <div
                    class="date"
                    id="copyr">
                    <p>Meridius © 2024</p>
                </div>
            </div>
        </div>
    </v-container>
</template>

<script>
import Vue from 'vue';
import gsap from 'gsap';

import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollTo from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollTrigger, ScrollTo);

function isInViewport(element, arg) {
    const rect = element.getBoundingClientRect();

    return rect.top + arg <= (window.innerHeight || document.documentElement.clientHeight);
}
function isInViewportB(element, arg) {
    const rect = element.getBoundingClientRect();
    return rect.bottom + arg <= (window.innerHeight || document.documentElement.clientHeight);
}
function animate(els) {
    els.forEach((el) => {
        if (!el.on) {
            if (isInViewport(el.el, -100)) {
                gsap.to(el.class, {
                    start: () => {
                        el.on = true;
                    },
                    onComplete: () => {
                        el.on = true;
                    },

                    translateY: 0,
                    translateX: 0,
                    opacity: 1,
                    duration: el.id == 10 ? 2.5 : 1.0,
                    ease: 'power4.inOut',
                    overwrite: true,
                });
            } else {
                gsap.to(el.class, {
                    onStart: () => {
                        el.on = true;
                    },
                    onComplete: () => {
                        el.on = false;
                    },
                    translateY: el.id == 10 ? 0 : 50,
                    translateX: el.id == 10 ? -50 : 0,

                    opacity: 0,
                    duration: 0.5,
                    ease: 'power4.inOut',
                    overwrite: true,
                });
            }
        }
    });
}

function animateImg(els) {
    els.forEach((el) => {
        if (!el.on) {
            if (isInViewport(el.el, -250)) {
                gsap.to(el.class, {
                    start: () => {
                        el.on = true;
                    },
                    onComplete: () => {
                        el.on = true;
                    },

                    translateY: 0,
                    translateX: 0,
                    scale: 1,
                    opacity: 1,
                    duration: 1.5,
                    ease: 'power4.out',
                    overwrite: true,
                });
            } else {
                gsap.to(el.class, {
                    onStart: () => {
                        el.on = true;
                    },
                    onComplete: () => {
                        el.on = false;
                    },
                    translateY: 200,
                    translateX: el.id % 3 == 2 ? 0 : el.id % 3 == 0 ? 100 : -100,
                    scale: 1,
                    opacity: 0,
                    duration: 0.5,
                    ease: 'elastic',
                    overwrite: true,
                });
            }
        }
    });
}
const animation = (el, img) => (event) => {
    animate(el);
    animateImg(img);
};
var elements, images;

let anim = gsap.timeline();

export default {
    name: 'Home',

    mounted() {
        // init some params
        window.addEventListener(
            'run',
            () => {
                this.main();
            },
            false
        );
        this.$nextTick(function () {
            // Code that will run only after the
            // entire view has been rendered
            this.$emit('home');
            this.$emit('loaded');
            setTimeout(() => {
                console.log('loaded');
            }, 1500);
        });

        //window.addEventListener('resize', this.chgViewPort);

        const arrowBtn = document.querySelector('#arrowBtn');
        arrowBtn?.addEventListener('mouseenter', (e) => {
            gsap.to('.arrow', {
                y: 110,
                duration: 0.8,
                ease: 'back.inOut(3)',
                overwrite: 'auto',
            });
        });
        arrowBtn?.addEventListener('mouseleave', (e) => {
            gsap.to('.arrow', {
                y: 100,
                duration: 0.5,
                ease: 'power3.out',
                overwrite: 'auto',
            });
        });
        arrowBtn?.addEventListener('click', (e) => {
            gsap.to(window, {
                scrollTo: innerHeight,
                duration: 1.5,
                ease: 'power1.inOut',
            });
        });
    },

    destroyed() {
        window.removeEventListener('scroll', animation(elements, images), true);
    },
    methods: {
        staticAnimation() {
            /*  anim.set('#black', {
                backgroundColor: '#000000',
                backgroundImage: 'none',
                overwrite: 'auto',
            }); */

            anim.set('#particles-js', {
                zIndex: 0,
                opacity: 0,
                overwrite: 'auto',
            });
            /*    if (navigator.userAgent.indexOf('Firefox') != -1) {
                tsParticles.loadJSON('particles-js', 'particles-moz.json').then(() => {
                    console.log('callback - particles.js config loaded');
                    const particles = tsParticles.domItem(0);
                    particles?.pause();
                });
            } else {
                tsParticles.loadJSON('particles-js', 'particles.json').then(() => {
                    console.log('callback - particles.js config loaded');
                    const particles = tsParticles.domItem(0);
                    particles?.pause();
                });
            }
 */
            anim.set('#lets, #build, #the, #future, #together', {
                marginLeft: '20px',
                marginRight: '20px',
                scale: 1.4,
                opacity: 0,
                overwrite: 'auto',
            });
            /*  anim.set('#imagine', {
                translateX: 100,
                translateY: 0,
                opacity: 0,
                overwrite: 'auto',
            }); */
            anim.set('#logo', {
                translateX: 0,
                translateY: -100,
                opacity: 0,
                overwrite: 'auto',
            });
            anim.set('#copyr', {
                translateX: 0,
                translateY: -100,
                opacity: 0,
                overwrite: 'auto',
            });

            anim.to(
                '#lets',
                {
                    onComplete: () => {
                        if (window.scrollY > 300) {
                            const particles = tsParticles.domItem(0);
                            particles?.pause();
                            const y = window.scrollY + 5;
                            window.scrollTo(0, y);
                        }
                    },
                    translateX: 0,
                    translateY: 0,
                    scale: 1,
                    opacity: 1,
                    duration: 1,
                    ease: 'power3.out',
                    overwrite: 'auto',
                },
                '+=0.3'
            );
            anim.to(
                '#build',
                {
                    onComplete: () => {
                        animation(elements, images);
                    },
                    translateX: 0,
                    translateY: 0,
                    scale: 1,
                    opacity: 1,
                    duration: 1,
                    ease: 'power3.out',
                    overwrite: 'auto',
                },
                '-=0.5'
            );
            anim.to(
                '#the',
                {
                    translateX: 0,
                    translateY: 0,
                    scale: 1,
                    opacity: 1,
                    duration: 1,
                    ease: 'power3.out',
                    overwrite: 'auto',
                },
                '-=0.5'
            );
            anim.to(
                '#future',
                {
                    translateX: 0,
                    translateY: 0,
                    scale: 1,
                    opacity: 1,
                    duration: 1,
                    ease: 'power3.out',
                    overwrite: 'auto',
                },
                '-=0.5'
            );
            anim.to(
                '#together',
                {
                    onStart: () => {
                        if (navigator.userAgent.indexOf('Firefox') != -1 || window.innerWidth < 1000) {
                            tsParticles.loadJSON('particles-js', 'particles-moz.json').then(() => {
                                console.log('callback - particles.js config loaded');
                            });
                        } else {
                            tsParticles.loadJSON('particles-js', 'particles.json').then(() => {
                                console.log('callback - particles.js config loaded');
                            });
                        }
                    },
                    translateX: 0,
                    translateY: 0,
                    scale: 1,
                    opacity: 1,
                    duration: 1,
                    ease: 'power3.out',
                    overwrite: 'auto',
                },
                '>'
            );
            /*  anim.to(
                '#imagine',
                {
                    translateX: 0,
                    translateY: 0,
                    opacity: 1,
                    duration: 0.7,
                    ease: 'power3.out',
                    overwrite: 'auto',
                },
                '>'
            ); */
            anim.to(
                '#logo',
                {
                    translateX: 0,
                    translateY: 0,
                    opacity: 1,
                    duration: 0.9,
                    ease: 'power3.out',
                    overwrite: 'auto',
                },
                '>'
            );
            anim.to(
                '#copyr',
                {
                    translateX: 0,
                    translateY: 0,
                    opacity: 1,
                    duration: 1.1,
                    ease: 'power3.out',
                    overwrite: 'auto',
                },
                '<'
            );

            anim.to(
                '#particles-js',
                {
                    onStart: () => {
                        const particles = tsParticles.domItem(0);
                        particles?.play();
                    },
                    opacity: 1,
                    duration: 4,
                    ease: 'power3.out',
                    overwrite: 'auto',
                },
                '<'
            );
        },

        init(els) {
            els.forEach((el) => {
                gsap.set(el.class, {
                    onStart: () => {
                        el.on = true;
                    },
                    onComplete: () => {
                        el.on = false;
                    },

                    translateY: el.id == 10 ? 0 : 50,
                    translateX: el.id == 10 ? -50 : 0,
                    opacity: 0,
                    overwrite: true,
                });
            });
        },

        initImg(els) {
            els.forEach((el) => {
                gsap.set(el.class, {
                    onStart: () => {
                        el.on = true;
                    },
                    onComplete: () => {
                        el.on = false;
                    },
                    translateY: 200,
                    translateX: el.id % 3 == 2 ? 0 : el.id % 3 == 0 ? 100 : -100,
                    scale: 1,
                    opacity: 0,
                    overwrite: true,
                });
            });
        },
        initCanvas(arg, items) {
            if (arg == 0) {
                items.forEach((e) => {
                    gsap.set(e, {
                        filter: 'brightness(1) grayscale(0)',
                        translateY: 0,
                        scale: 1,
                        zIndex: 0,
                    });
                });
            } else {
                let duration = 0.2;
                items.forEach((e) => {
                    gsap.to(e, {
                        translateY: 0,
                        scale: 1,
                        duration: 0.5,
                        ease: 'power3.out',
                        overwrite: 'auto',
                    });
                    gsap.to(e, {
                        filter: 'brightness(1) grayscale(0)',
                        duration: 0.3,
                        ease: 'power3.in',
                        overwrite: 'auto',
                    });
                    gsap.set(arg, {
                        zIndex: 0,
                        overwrite: 'auto',
                    });
                    duration += 0.1;
                });
            }
        },
        animateCanvas(arg, items) {
            gsap.set(arg, {
                zIndex: 1,
                overwrite: 'auto',
            });
            gsap.to(arg, {
                translateY: -6,
                scale: 1.02,
                duration: 0.5,
                ease: 'power3.out',
                overwrite: 'auto',
            });

            let duration = 0.5;
            items.forEach((e) => {
                if (arg != e) {
                    gsap.to(e, {
                        filter: 'brightness(0.6) grayscale(0.6)',
                        duration: duration,
                        ease: 'power3.out',
                        overwrite: false,
                    });
                    duration += 0.2;
                }
            });
        },

        main() {
            //trigger();

            /*
    initCanvas(0, cIds);

    cIds.forEach((e) => {
        const el = document.querySelector(e);
        el.addEventListener("mouseenter", (ev) => {
            animateCanvas(e, cIds);
        });
        el.addEventListener("mouseleave", (ev) => {
            initCanvas(e, cIds);
        });
    });*/

            this.staticAnimation();
        },
    },
    data() {
        return {
            show: false,
        };
    },
    metaInfo() {
        return {
            title: 'Meridius - Digitalisation et développement d’applications',
            meta: [
                {
                    name: 'description',
                    content:
                        'Meridius pose comme objectif d’outiller les entreprises avec des logiciels informatiques à la pointe de la technologie.',
                },
                { property: 'og:title', content: 'Meridius - Digitalisation et développement d’applications' },
                { property: 'og:site_name', content: 'Meridius' },
                { property: 'og:type', content: 'website' },
                { name: 'robots', content: 'index,follow' },
            ],
        };
    },
};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
.wide {
    height: 100%;
    background: rgb(0, 0, 0);
    font-family: 'Montserrat', sans-serif;
}
.text-ularge {
    font-size: 7rem !important;
    font-weight: 900;
    line-height: 8rem !important;
    letter-spacing: -0.015625em !important;
    font-family: 'Roboto', sans-serif !important;
}
.logo-fixe {
    margin-left: 10vw;
    max-width: 168px;
}
.date {
    margin-right: 10vw;
    font-weight: 700;
    color: #fff;
    max-width: 168px;
}
.banner {
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.banner-tw {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.banner-lw {
    position: absolute;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    justify-content: space-between;
    align-items: flex-end;
}
.about {
    height: 130vh;
    background-position: center;
    z-index: 3;
    background-color: #ebeeff;
    background-size: cover;
}
.about-sm {
    background-image: url(../../public/man-wearing-smart-glasses-touching-virtual-screen-dark.jpg);
}
.about-lg {
    background-image: url(../../public/man-wearing-smart-glasses-touching-virtual-screen.jpg);
}
.about-lg-content {
    background-color: #ebeeff77;
    box-shadow: 0px 0px 19px 20px #ebeeff77;
    border-radius: 20px;
}
.content-lg {
    max-width: 460px;
    text-align: justify;
    margin-left: 7vw;
}
.content-sm {
    max-width: 460px;
    text-align: justify;
    margin: auto;
}

.experts {
    background: #fff;
}
.expertises {
    background-color: white;
    transform: translateY(-200px);
    margin-bottom: -200px;
}
.content-ex {
    transform: translateY(-6vh);
}
.tech {
    height: 150vh;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.tech-text-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.tech-text-content {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.rect-container {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.rect {
    background-color: #333;
    border-radius: 55px;
    width: 75vw !important;
    max-height: 500px;
    margin-top: 200px;
    margin-bottom: -200px;
    overflow: hidden;
    z-index: 3;
}
.tech-carousel {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.contact {
    transform: translateY(-200px);
    margin-bottom: -200px;
    height: fit-content;
}
.contact-form {
    margin-top: 400px;
}

.blob {
    transform: translate(383px, -60vh);
    position: absolute;
    background-image: url(../../public/blob-b.png);
    background-position-x: right;
    background-position-y: center;
    width: 100vw;
    height: 80vh;
    background-size: contain;
    z-index: 3;
}

.banner-tw .light {
    font-weight: 400 !important;
    margin-right: 20px;
}

.banner-tw .bold {
    font-weight: 900 !important;
}
.v-btn.v-size--default {
    color: #fff !important;
}
</style>

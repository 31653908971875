var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"wide white--text",staticStyle:{"padding":"0px"},attrs:{"fluid":""}},[_c('div',{attrs:{"id":"particles-js"}}),_c('article',{staticStyle:{"position":"absolute","z-index":"8","top":"calc(70vh + 25px)","width":"100vw","display":"flex","flex-direction":"column","align-content":"center","align-items":"center"}},[_c('h1',{staticStyle:{"opacity":"0","user-select":"none"}},[_vm._v("Let's build the furture together")]),_c('p',{staticClass:"text-center px-2",staticStyle:{"max-width":"500px"}},[_vm._v(" Parce que chaque entreprise est unique, il est essentiel de lui développer une solution numérique adaptée à son fonctionnement. ")])]),_c('div',{staticClass:"white--text banner"},[_c('div',{staticClass:"banner-tw"},[_c('div',[_c('v-row',{staticClass:"no-wrap",staticStyle:{"display":"flex","align-items":"baseline","justify-content":"center"},attrs:{"no-gutters":""}},[_c('v-col',{class:_vm.$vuetify.breakpoint.smAndDown
                                ? 'text-body-1 light'
                                : _vm.$vuetify.breakpoint.lgAndUp
                                ? 'text-h2 light text-end'
                                : 'text-h3 light',attrs:{"cols":"3","md":"3","id":"lets"}},[_vm._v("let's")]),_c('v-col',{class:_vm.$vuetify.breakpoint.smAndDown
                                ? 'text-h4 bold'
                                : _vm.$vuetify.breakpoint.lgAndUp
                                ? 'text-ularge bold'
                                : 'text-h2 bold',attrs:{"cols":"7","md":"7","id":"build"}},[_vm._v(" build ")])],1),_c('v-row',{staticClass:"no-wrap",staticStyle:{"display":"flex","align-items":"baseline","justify-content":"center"},attrs:{"no-gutters":""}},[_c('v-col',{class:_vm.$vuetify.breakpoint.smAndDown
                                ? 'text-body-1 light'
                                : _vm.$vuetify.breakpoint.lgAndUp
                                ? 'text-h2 pl-7 light text-end'
                                : 'text-h3 light',attrs:{"cols":"3","md":"3","id":"the"}},[_vm._v("the")]),_c('v-col',{class:_vm.$vuetify.breakpoint.smAndDown
                                ? 'text-h4 bold'
                                : _vm.$vuetify.breakpoint.lgAndUp
                                ? 'text-ularge bold'
                                : 'text-h2 bold',attrs:{"cols":"7","md":"7","id":"future"}},[_vm._v(" future ")])],1),_c('v-row',{staticClass:"no-wrap",staticStyle:{"display":"flex","align-items":"baseline","justify-content":"center"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"3","md":"3"}}),_c('v-col',{class:_vm.$vuetify.breakpoint.smAndDown
                                ? 'text-h4 bold'
                                : _vm.$vuetify.breakpoint.lgAndUp
                                ? 'text-ularge pl-5 bold'
                                : 'text-h2 bold ml-n15',attrs:{"cols":"7","md":"7","id":"together"}},[_vm._v(" together ")])],1)],1),_c('v-spacer')],1),_c('div',{staticClass:"banner-lw"},[(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-img',{staticClass:"logo-fixe",attrs:{"id":"logo","src":"/logow.png","height":"75","contain":"","alt":"Meridius AI Solutions"}}):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('svg',{staticClass:"logo-fixe mb-2",attrs:{"id":"logo","xmlns":"http://www.w3.org/2000/svg","width":"45","height":"45","viewBox":"0 0 77.654 77.654"}},[_c('g',{attrs:{"id":"Groupe_2","data-name":"Groupe 2","transform":"translate(-166.184 -2.322)"}},[_c('g',{attrs:{"id":"Groupe_1","data-name":"Groupe 1","transform":"translate(166.184 2.322)"}},[_c('path',{attrs:{"id":"path_1","data-name":"Tracé 1","d":"M205.011,79.976a38.827,38.827,0,1,1,38.827-38.827A38.871,38.871,0,0,1,205.011,79.976Zm0-72.623a33.8,33.8,0,1,0,33.8,33.8A33.834,33.834,0,0,0,205.011,7.353Z","transform":"translate(-166.184 -2.322)","fill":"#fff"}})]),_c('path',{attrs:{"id":"path_2","data-name":"Tracé 2","d":"M210.034,63.591a6.4,6.4,0,0,1,6.157-4.25c3.972,0,7.07,2.661,7.07,6.712V79.836c0,1.628.715,2.383,2.026,2.383.913,0,1.311-.556,1.668-1.669l5.481-16.96a6.348,6.348,0,0,1,6.157-4.25c3.972,0,7.07,2.661,7.07,6.712V86.787h-5.084V66.53c0-1.589-.715-2.383-2.066-2.383-.874,0-1.271.635-1.628,1.747l-5.561,16.921a6.159,6.159,0,0,1-6.116,4.29c-3.932,0-7.03-2.661-7.03-6.713V66.53c0-1.589-.715-2.383-2.065-2.383-.874,0-1.231.635-1.629,1.747l-7.308,20.893h-5.4Z","transform":"translate(-20.019 -32.074)","fill":"#fff"}})])]):_vm._e(),_c('div',{staticClass:"date",attrs:{"id":"copyr"}},[_c('p',[_vm._v("Meridius © 2024")])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }